@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


* {
  scrollbar-width: thin;
  scrollbar-color: gray;
  padding:0;
  margin: 0;
  box-sizing: border-box;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

/* .mapboxgl-canvas{
  display: none;
} */
.mapboxgl-ctrl-attrib-inner{
  display: none;
}
.mapboxgl-ctrl-geocoder{
  width: "100%";
  max-width: "500px";
}


@font-face {
  font-family: "Mont";
  /* font-weight: 100 1000; */
  src: url("../public/fonts/phagspa.ttf") format("truetype");
}


@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')